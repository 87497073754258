import store from 'store'

const getStoredSettings = <T extends object>(storedSettings: T): T => {
  const settings = {} as T

  ;(Object.keys(storedSettings) as Array<keyof T>).forEach((key) => {
    const item = store.get(`app.settings.${String(key)}`)
    settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })

  return settings
}

export default getStoredSettings
