import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import store from 'store'
import getStoredSettings from 'utils/getStoredSettings'

interface ISettingsState {
  logo: string
  theme: 'default' | 'dark'
  locale: 'ru-RU'
  isSidebarOpen: boolean
  isSupportChatOpen: boolean
  isMobileView: boolean
  isMobileMenuOpen: boolean
  isMenuCollapsed: boolean
  isPreselectedOpen: boolean
  preselectedVariant: 'default'
  menuColor: 'default' | 'dark'
  isAuthTopbar: boolean
  primaryColor: string
  leftMenuWidth: number
  isCardShadow: boolean
}

const initialState = getStoredSettings<ISettingsState>({
  logo: 'Slaver',
  theme: 'default',
  locale: 'ru-RU',
  isSidebarOpen: false,
  isSupportChatOpen: false,
  isMobileView: false,
  isMobileMenuOpen: false,
  isMenuCollapsed: false,
  isPreselectedOpen: false,
  preselectedVariant: 'default',
  menuColor: 'default',
  isAuthTopbar: true,
  primaryColor: '#4b7cf3',
  leftMenuWidth: 256,
  isCardShadow: true,
})

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    getSettingsInfo: (state: ISettingsState, action: PayloadAction<ISettingsState>) => ({
      ...state,
      ...action.payload,
    }),
    changeSettings: (state: any, action: PayloadAction<any>) => {
      store.set(`app.settings.${action.payload.setting}`, action.payload.value)

      state[action.payload.setting] = action.payload.value
    },
  },
})

export const { getSettingsInfo, changeSettings } = settingsSlice.actions

export default settingsSlice.reducer
