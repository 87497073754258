export const checkItemTitle = (initialValue: any) => {
  switch (initialValue) {
    case 'Team':
      return 'Команда'
    case 'BotPlatform':
      return 'Бот платформа'
    case 'Dialog':
      return 'Диалоги'
    case 'Project':
      return 'Проект'
    case 'Manager':
      return 'Центр управления'
    case 'Analytics':
      return 'Аналитика'
    default:
      return initialValue
  }
}
