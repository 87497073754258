import { changeSettings } from 'redux/state/settingsSlice'
import { store } from 'redux/store'
import localStore from 'store'

const isMenuToggled = (): void => {
  const shouldToggle = global.window.innerWidth < 1024
  const prevState = localStore.get('app.settings.isMenuCollapsed')

  if (shouldToggle || prevState) {
    store.dispatch(
      changeSettings({
        setting: 'isMenuCollapsed',
        value: true,
      }),
    )
  }
}

export default isMenuToggled
