import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/dist/query'
import { createApi } from '@reduxjs/toolkit/query/react'
import store from 'store'
import prepareHeaders from '../../utils/prepareHeaders'

const { REACT_APP_API_URL, REACT_APP_API_SCREEN_URL } = process.env

const baseQuery = (project = '', url = REACT_APP_API_URL) =>
  fetchBaseQuery({
    baseUrl: `${url}/${project}`,
    prepareHeaders,
  })

const dynamicBaseQuery = (args: string | FetchArgs, WebApi: any, extraOptions: {}) => {
  const projectId = store.get('project._id') || WebApi.getState().project._id

  const rawBaseQuery = baseQuery(projectId)
  return rawBaseQuery(args, WebApi, extraOptions)
}

const staticApi = createApi({
  reducerPath: 'api/state',
  baseQuery: baseQuery(),
  endpoints: () => ({}),
})

const screenApi = createApi({
  reducerPath: 'api/screen',
  baseQuery: baseQuery('', REACT_APP_API_SCREEN_URL),
  endpoints: () => ({}),
})

const emptyApi = createApi({
  reducerPath: 'api/dynamic',
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
})

export const api = staticApi.enhanceEndpoints({ addTagTypes: ['AUTH', 'PRODJ'] })
export const screenSoftApi = screenApi.enhanceEndpoints({
  addTagTypes: ['CATEGORY', 'TEMPLATE', 'FONT'],
})
export const dynamicApi = emptyApi.enhanceEndpoints({
  addTagTypes: [
    'POS',
    'GEO',
    'MEMB',
    'AGENT',
    'BR',
    'CAP',
    'TRANSIT',
    'DQ',
    'PA',
    'CARD_POOL',
    'ASSIGNMENT',
    'SI',
    'IA',
    'AUTHD',
    'STUB_CHANNEL'
  ],
})
