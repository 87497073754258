import { useAppSelector } from 'hooks/useAppSelector'
import WrapperLayout from 'layouts/WrapperLayout'
import { FC, memo } from 'react'
import { ELayout, getLayout } from 'utils/getLayout'
import { IContainer } from './Container.interface'

const Container: FC<IContainer> = ({ children }) => {
  const pathname = useAppSelector((state) => state.router.location.pathname)
  const isAuthLayout = getLayout(pathname) === ELayout.Auth
  const isHallFameLayout = getLayout(pathname) === ELayout.Hall
  const isMainLayout = getLayout(pathname) === ELayout.Main
  const isAssigmentLayout = getLayout(pathname) === ELayout.Assigment
  const isTvVersion = getLayout(pathname) === ELayout.Tv

  if (isHallFameLayout || isTvVersion) {
    return <WrapperLayout isLogo>{children}</WrapperLayout>
  }

  if (isAuthLayout) {
    return <WrapperLayout>{children}</WrapperLayout>
  }

  if (isAssigmentLayout) {
    return <WrapperLayout isLogo>{children}</WrapperLayout>
  }

  if (isMainLayout) {
    return (
      <WrapperLayout isLogo={false} isSelectProject isBonding>
        {children}
      </WrapperLayout>
    )
  }

  return <div>{children}</div>
}

export default memo(Container)
