import { Helmet } from 'react-helmet'

import Container from 'components/Container'
import { useAppSelector } from 'hooks/useAppSelector'
import { FC, memo } from 'react'
import { ILayout } from './Layout.interface'

const Layout: FC<ILayout> = ({ children }) => {
  const title = useAppSelector((state) => state.settings.logo)

  return (
    <>
      <Helmet titleTemplate={`${title} | %s`} title={title} />
      <Container>{children}</Container>
    </>
  )
}

export default memo(Layout)
