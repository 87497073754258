import { Layout } from 'antd'
import Breadcrumbs from 'components/Breadcrumbs'
import Footer from 'components/Footer'
import Sidebar from 'components/Sidebar'
import ThemeWidget from 'components/ThemeWidget'
import TopBar from 'components/TopBar'
import { useAppSelector } from 'hooks/useAppSelector'
import { FC, memo } from 'react'
import { Redirect } from 'react-router-dom'
import { useAccountQuery } from 'redux/api/apiAuthSlice'
import { ELayout, getLayout } from 'utils/getLayout'
import { IWrapperLayout } from './WrapperLayout.interface'

const WrapperLayout: FC<IWrapperLayout> = ({ children, ...props }) => {
  const { isLoading, isError } = useAccountQuery({})

  const pathname = useAppSelector((state) => state.router.location.pathname)
  const isMainLayout = getLayout(pathname) === ELayout.Main
  const isAuthLayout = getLayout(pathname) === ELayout.Auth
  const isTVLayout = getLayout(pathname) === ELayout.Tv
  const isHallFameLayout = getLayout(pathname) === ELayout.Hall

  if (isLoading) {
    return null
  }

  if (!isLoading && isError && !isAuthLayout && !isTVLayout && !isHallFameLayout) {
    return <Redirect to="/auth/login" />
  }

  return (
    <Layout className="h-screen overflow-hidden">
      <ThemeWidget />
      {isMainLayout && <Sidebar />}
      <Layout className="overflow-auto">
        <TopBar {...props} />
        {isMainLayout && <Breadcrumbs />}
        <Layout.Content className="w-full p-7 min-h-screen flex flex-col">
          <div className="flex-1">{children}</div>
          <Footer />
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

export default memo(WrapperLayout)
