import { Button, Layout } from 'antd'
import Logo from 'components/Logo'
import ProjectManagement from 'components/ProjectManagement'
import UserMenu from 'components/UserMenu'
import { useAppSelector } from 'hooks/useAppSelector'
import { FC, memo } from 'react'
import { Link } from 'react-router-dom'
import { ITopBar } from './TopBar.interface'

const TopBar: FC<ITopBar> = ({ isLogo = true, isSelectProject = false, isBonding = false }) => {
  const logo = useAppSelector((state) => state.settings.logo)
  const isAuth = useAppSelector((state) => state.user.authorized)

  return (
    <Layout.Header className="!bg-white sticky !p-0 w-full">
      <div className="flex flex-nowrap items-center px-4 sm:px-[30px] h-full dark:bg-[#0c0c1b] border-b border-[#e4e9f0] dark:border-[#232135]">
        <div className="mr-auto flex gap-4 items-center">
          {isLogo && <Logo isMenuCollapsed={false} title={logo} isMobileView={false} />}
          {isSelectProject && <ProjectManagement />}
        </div>
        {isBonding && (
          <div className="mr-4">
            <Link to="/assignment/dashboard">
              <Button type="primary" icon={<i className="fe fe-unlock mr-2" />}>
                Связки
              </Button>
            </Link>
          </div>
        )}
        {isAuth && (
          <div>
            <UserMenu />
          </div>
        )}
      </div>
    </Layout.Header>
  )
}

export default memo(TopBar)
