import { TMenu } from '../menu.interface'

export const mailRole: TMenu[] = [
  {
    title: 'Рассылки (new)',
    key: 'mail',
    url: '/botPlatform/mail',
    icon: 'fe fe-mail text-amber-300',
  },
]
