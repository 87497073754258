export enum ELayout {
  Public = 'public',
  Assigment = 'assigment',
  Auth = 'auth',
  Main = 'main',
  Hall = 'hallFame',
  Tv = 'tv',
}

type Layout =
  | ELayout.Public
  | ELayout.Assigment
  | ELayout.Auth
  | ELayout.Main
  | ELayout.Hall
  | ELayout.Tv

export const getLayout = (pathname: string): Layout => {
  if (pathname === '/') {
    return ELayout.Public
  }

  if (pathname.startsWith('/assignment')) {
    return ELayout.Assigment
  }

  if (pathname.startsWith('/hallFame')) {
    return ELayout.Hall
  }

  if (pathname.startsWith('/tv')) {
    return ELayout.Tv
  }

  if (/^\/auth(?=\/|$)/i.test(pathname)) {
    return ELayout.Auth
  }

  return ELayout.Main
}
