import { FC, useEffect } from 'react'

// antd core & themes styles
import '@vb/css/vendors/antd/themes/dark.less'
import '@vb/css/vendors/antd/themes/default.less'

import { useAppDispatch } from 'hooks/useAppDispatch'
import { useAppSelector } from 'hooks/useAppSelector'
import { changeSettings } from 'redux/state/settingsSlice'
import { IStylesLoader } from './StylesLoader.interface'

const StylesLoader: FC<IStylesLoader> = ({ children }) => {
  const dispatch = useAppDispatch()
  const theme = useAppSelector((state) => state.settings.theme)

  // listen & set vb-theme (dark, default, ...)
  useEffect(() => {
    document.querySelector('html')?.setAttribute('data-vb-theme', theme)
    dispatch(
      changeSettings({
        setting: 'menuColor',
        value: theme === 'dark' ? 'dark' : 'white',
      }),
    )
  }, [theme, dispatch])

  return children
}

export default StylesLoader
