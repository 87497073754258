import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import store from 'store'

interface IUserState {
  id: string
  name: string
  role: string[]
  email: string
  avatar: string
  accessToken: string
  authorized: boolean
  loading: boolean
}

const initialState: IUserState = {
  id: '',
  name: '',
  role: [],
  email: '',
  avatar: '',
  accessToken: store.get('accessToken') || '',
  authorized: false,
  loading: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserInfo: (state: IUserState, action: PayloadAction<IUserState>) => ({
      ...state,
      ...action.payload,
    }),
    logout: (state: IUserState) => {
      state.id = ''
      state.name = ''
      state.role = []
      state.email = ''
      state.avatar = ''
      state.accessToken = ''
      state.authorized = false
      state.loading = false
    },
  },
})

export const { getUserInfo, logout } = userSlice.actions

export default userSlice.reducer
