import { history } from 'redux/store'
import isMenuToggled from './isMenuToggled'
import isMobileView from './isMobileView'
import searchSettings from './searchSettings'

const setup = () => {
  searchSettings(history.location.search)

  history.listen((params) => {
    const { search } = params
    searchSettings(search)
  })

  isMobileView(true)
  isMenuToggled()
}

export default setup
