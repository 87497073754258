import { Dropdown, MenuProps } from 'antd'

import { CaretDownOutlined } from '@ant-design/icons'
import { useAppSelector } from 'hooks/useAppSelector'
import { FC, memo, useCallback, useMemo } from 'react'
import { useGetAllProjectsQuery, useLazyGetSingleProjectQuery } from 'redux/api/apiProjectSlice'

const ProjectManagement: FC = () => {
  const [getSingleProject] = useLazyGetSingleProjectQuery()
  const { data } = useGetAllProjectsQuery({})

  const { _id, title } = useAppSelector((state) => state.project)

  const handleClickProject = useCallback(
    (currentProject: boolean, id: string) => {
      if (currentProject) {
        return
      }

      getSingleProject(id)
    },
    [getSingleProject],
  )

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'projectMeVisible',
        type: 'group',
        label: 'Доступные мне',
        children: data?.result.member.map((el: any) => {
          const currentProject = el._id === _id

          return {
            key: el._id,
            icon: <i className={`fe ${currentProject ? 'fe-check-circle' : 'fe-circle'} mr-2`} />,
            onClick: () => handleClickProject(currentProject, el._id),
            label: el.title,
          }
        }),
      },
    ],
    [_id, data?.result.member, handleClickProject],
  )

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <div className="flex items-center gap-2 hover:text-blue-500 transition-all duration-200 cursor-pointer">
        <i className="text-base fe fe-folder" />
        <div className="leading-4">{title}</div>
        <CaretDownOutlined className="text-xs" />
      </div>
    </Dropdown>
  )
}

export default memo(ProjectManagement)
