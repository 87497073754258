import { FC, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { routes } from 'routers/Router.config'

import { useAppSelector } from 'hooks/useAppSelector'
import Layout from 'layouts'
import setup from 'utils/setup'

const Router: FC = () => {
  const location = useAppSelector((state) => state.router.location)

  setup()

  return (
    <Layout>
      <SwitchTransition>
        <CSSTransition key={location.pathname} appear classNames="slide-fadein-right" timeout={300}>
          <Switch location={location}>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            {routes.map(({ path, Component, exact }) => (
              <Route
                path={path}
                key={path}
                exact={exact}
                render={() => (
                  <div className="slide-fadein-right">
                    <Suspense fallback={null}>
                      <Component />
                    </Suspense>
                  </div>
                )}
              />
            ))}
            <Redirect to="/404" />
          </Switch>
        </CSSTransition>
      </SwitchTransition>
    </Layout>
  )
}

export default Router
