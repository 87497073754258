import { changeSettings } from 'redux/state/settingsSlice'
import { store } from 'redux/store'
import localStore from 'store'

const isMobileView = (load = false): void => {
  const currentState = global.window.innerWidth < 768
  const prevState = localStore.get('app.settings.isMobileView')

  if (currentState !== prevState || load) {
    store.dispatch(
      changeSettings({
        setting: 'isMobileView',
        value: currentState,
      }),
    )
  }
}

export default isMobileView
