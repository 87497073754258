import { Layout, Menu, SiderProps } from 'antd'
import Logo from 'components/Logo'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useAppSelector } from 'hooks/useAppSelector'
import { find } from 'lodash'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IMenuItem } from 'redux/state/menuSlice'
import { changeSettings } from 'redux/state/settingsSlice'
import { IMenuProjectOwnerItemChildren } from 'services/menu/menu.interface'
import store from 'store'
import { getMenuItem } from 'utils/getMenuItem'
import { layoutClassName, menuClassName } from './Navigation.config'

const Navigation: FC = () => {
  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])

  const isMenuCollapsed = useAppSelector((state) => state.settings.isMenuCollapsed)
  const isMobileView = useAppSelector((state) => state.settings.isMobileView)
  const leftMenuWidth = useAppSelector((state) => state.settings.leftMenuWidth)
  const logo = useAppSelector((state) => state.settings.logo)

  const menuData = useAppSelector((state) => state.menu.menuData)
  const dispatch = useAppDispatch()

  const location = useLocation()

  const applySelectedKeys = useCallback(() => {
    const flattenItems = (items: IMenuItem[], key: 'children'): IMenuItem[] =>
      items.reduce((prev: IMenuItem[], current) => {
        prev.push(current)
        if (Array.isArray(current[key])) {
          return prev.concat(flattenItems(current[key] as IMenuItem[], key))
        }
        return prev
      }, [])
    const selectedItem = find(flattenItems(menuData, 'children'), ['url', location.pathname])
    setSelectedKeys(selectedItem ? [selectedItem.key] : [])
  }, [location.pathname, menuData])

  useEffect(() => {
    applySelectedKeys()
  }, [applySelectedKeys])

  const onCollapse = useCallback(
    (_, type) => {
      if (type === 'responsive' && isMenuCollapsed) {
        return
      }
      dispatch(
        changeSettings({
          setting: 'isMenuCollapsed',
          value: !isMenuCollapsed,
        }),
      )
      setOpenedKeys([])
    },
    [dispatch, isMenuCollapsed],
  )

  const onOpenChange = useCallback((keys) => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }, [])

  const handleClick = useCallback((e) => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }, [])

  const generateSubmenu = useCallback(
    (children: IMenuProjectOwnerItemChildren[]) =>
      children.map(({ key, title, url }) => getMenuItem(<Link to={url}>{title}</Link>, key)),
    [],
  )

  const generateItem = useCallback(
    ({ key, category, title, url, icon, children }: IMenuItem) => {
      if (category) {
        if (isMenuCollapsed && !isMobileView) {
          return getMenuItem(null, key, null, undefined, 'divider')
        }

        return getMenuItem(title, key, null, [], 'group')
      }

      if (children) {
        return getMenuItem(title, key, <i className={`${icon}`} />, generateSubmenu(children))
      }

      return getMenuItem(<Link to={url}>{title}</Link>, key, <i className={`${icon}`} />)
    },
    [generateSubmenu, isMenuCollapsed, isMobileView],
  )

  const generateMenuItems = useMemo(
    () => menuData.map((menuItem) => generateItem(menuItem)),
    [generateItem, menuData],
  )

  const menuSettings: SiderProps = useMemo(
    () => ({
      width: leftMenuWidth,
      collapsible: !isMobileView,
      collapsed: isMobileView ? false : isMenuCollapsed,
      onCollapse,
      breakpoint: isMobileView ? undefined : 'lg',
    }),
    [isMenuCollapsed, isMobileView, leftMenuWidth, onCollapse],
  )

  return (
    <Layout.Sider {...menuSettings} className={layoutClassName}>
      <div className="overflow-auto h-full dark:bg-[#0c0c1b]">
        <div className="px-6 py-[16px]">
          <Logo isMenuCollapsed={isMenuCollapsed} isMobileView={isMobileView} title={logo} />
        </div>
        <Menu
          onClick={handleClick}
          selectedKeys={selectedKeys}
          openKeys={openedKeys}
          onOpenChange={onOpenChange}
          mode="inline"
          inlineIndent={15}
          items={generateMenuItems}
          className={menuClassName}
        />
      </div>
    </Layout.Sider>
  )
}

export default memo(Navigation)
