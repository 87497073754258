import { notification } from 'antd'
import { getMenuInfo } from 'redux/state/menuSlice'
import projectSlice, { getProjectInfo } from 'redux/state/projectSlice'
import { history } from 'redux/store'
import store from 'store'
import { getMenu } from 'utils/getMenu'
import { api } from './api'

export const apiProjectSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllProjects: builder.query({
      query: () => 'slaverProjects/get',
      providesTags: ['PRODJ', 'AUTH'],
    }),
    createProject: builder.mutation({
      query: (body) => ({
        url: 'slaverProjects/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PRODJ'],
    }),
    acceptInviteProject: builder.mutation({
      query: (body) => ({
        url: 'slaverProjects/acceptInvite',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PRODJ'],
    }),
    userSettingsProject: builder.mutation({
      query: (body) => ({
        url: 'slaverProjects/setUserSettings',
        method: 'POST',
        body,
      }),
    }),
    getCurrentProject: builder.query({
      query: (projectId) => ({
        url: 'slaverProjects/getCurrent',
        params: {
          projectId,
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          const menuList = await getMenu(data.result)

          store.set('project._id', data.result._id)
          store.set('project.title', data.result.title)

          dispatch(getProjectInfo(data.result))
          dispatch(getMenuInfo(menuList))
        } catch (err) {
          dispatch(getProjectInfo(projectSlice.initialState))

          store.set('project._id', null)
          store.set('project.title', null)
          notification.warning({ message: err.error.data.error })
        }
      },
    }),
    getSingleProject: builder.query({
      query: (id) => `slaverProjects/getSingle/${id}`,
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          const menuList = await getMenu(data.result)

          store.set('project._id', data.result._id)
          store.set('project.title', data.result.title)

          dispatch(getProjectInfo(data.result))
          dispatch(getMenuInfo(menuList))
        } catch (err) {
          dispatch(getProjectInfo(projectSlice.initialState))

          store.set('project._id', null)
          store.set('project.title', null)
          history.push('/auth/project')
        }
      },
    }),
  }),
})

export const {
  useGetCurrentProjectQuery,
  useGetAllProjectsQuery,
  useLazyGetSingleProjectQuery,
  useCreateProjectMutation,
  useAcceptInviteProjectMutation,
  useUserSettingsProjectMutation,
} = apiProjectSlice
