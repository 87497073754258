import { TMenu } from '../menu.interface'

export const dialogAnalyticsRole: TMenu[] = [
  {
    title: 'Аналитика (воронка)',
    key: 'menu-analytics-dialogs',
    url: '/dialog/analytics',
    icon: 'fe fe-bar-chart',
  },
]
