import { TMenu } from '../menu.interface'

export const screenSoftRole: TMenu[] = [
  {
    title: 'Скриншоты',
    key: 'menu-screen-soft',
    url: '/dialog/screenSoft',
    icon: 'fe fe-crop',
  },
]
