import { FC } from 'react'
import { history } from 'redux/store'
import { ILogo } from './Logo.interface'

const Logo: FC<ILogo> = ({ isMenuCollapsed, title, isMobileView }) => {
  const isHiddenTitleClass = isMobileView || !isMenuCollapsed ? 'block' : 'hidden'

  const handleClick = () => {
    history.push('/')
  }

  return (
    <div className="flex items-center cursor-pointer" onClick={handleClick}>
      <div className="w-[32px]">
        <img src="/logo.png" alt={title} className="w-full" />
      </div>
      <div className={`text-xl font-bold text-black dark:text-white ${isHiddenTitleClass}`}>
        {title}
      </div>
      {!isMenuCollapsed && (
        <span className="ml-1 p-1 text-xs font-bold rounded-[25%] text-black bg-yellow-300">
          Pro
        </span>
      )}
    </div>
  )
}

export default Logo
