import { UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, MenuProps } from 'antd'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useAppSelector } from 'hooks/useAppSelector'
import { FC, useCallback, useMemo } from 'react'
import { getProjectInfo, initialState } from 'redux/state/projectSlice'
import { logout } from 'redux/state/userSlice'
import { history } from 'redux/store'
import store from 'store'

const UserMenu: FC = () => {
  const dispatch = useAppDispatch()
  const name = useAppSelector((state) => state.user.name)

  const onLogout = useCallback(() => {
    dispatch(logout())
    dispatch(getProjectInfo(initialState))

    history.push('/auth/login')

    store.set('accessToken', null)
    store.set('project._id', null)
    store.set('project.title', null)
  }, [dispatch])

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'userMenu',
        type: 'group',
        label: <strong className="text-blue-900 dark:text-[#AEAEE0]">{name || 'Anonymous'}</strong>,
        children: [
          { type: 'divider' },
          {
            key: 'userMenuSettings',
            icon: <i className="fe fe-settings mr-2" />,
            onClick: () => history.push('/project/settings'),
            label: 'Настройки проекта',
          },
          { type: 'divider' },
          {
            key: 'userMenuLogout',
            icon: <i className="fe fe-log-out mr-2" />,
            onClick: onLogout,
            label: 'Выйти',
          },
        ],
      },
    ],
    [name, onLogout],
  )

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <div className="cursor-pointer">
        <Avatar
          className="!bg-gray-300 dark:!bg-gray-400"
          shape="square"
          size="large"
          icon={<UserOutlined />}
        />
      </div>
    </Dropdown>
  )
}

export default UserMenu
