import { IProjectState } from 'redux/state/projectSlice'
import { store } from 'redux/store'
import AccessList from 'services/AccessList'
import getMenuData from 'services/menu'

export const getMenu = async (project: IProjectState) => {
  const { user } = store.getState()

  if (project.owner === user.id) {
    const fullAccess: string[] = await AccessList.map((el) => el.value)

    return getMenuData(fullAccess, true)
  }

  return getMenuData(user.role)
}
