import { MenuOutlined } from '@ant-design/icons'
import { Drawer } from 'antd'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useAppSelector } from 'hooks/useAppSelector'
import { FC, memo, useCallback } from 'react'
import { changeSettings } from 'redux/state/settingsSlice'
import Navigation from './Navigation'

const Sidebar: FC = () => {
  const { isMobileMenuOpen, isMobileView, leftMenuWidth } = useAppSelector(
    (state) => state.settings,
  )

  const dispatch = useAppDispatch()

  const toggleMobileMenu = useCallback(() => {
    dispatch(
      changeSettings({
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      }),
    )
  }, [dispatch, isMobileMenuOpen])

  if (isMobileView) {
    return (
      <div>
        <div
          className="fixed left-0 top-[72px] w-12 h-12 cursor-pointer shadow-md rounded-r-md bg-black flex items-center justify-center z-10"
          onClick={toggleMobileMenu}
          onFocus={(e) => {
            e.preventDefault()
          }}
          role="button"
          tabIndex={0}
        >
          <MenuOutlined className="text-white text-xl" />
        </div>
        <Drawer
          closable={false}
          open={isMobileMenuOpen}
          placement="left"
          bodyStyle={{
            padding: 0,
          }}
          onClose={toggleMobileMenu}
          maskClosable
          getContainer={undefined}
          width={leftMenuWidth}
        >
          <Navigation />
        </Drawer>
      </div>
    )
  }

  return <Navigation />
}

export default memo(Sidebar)
