import { TMenu } from '../menu.interface'

export const incomeAssignmentRole: TMenu[] = [
  {
    title: 'Крипторотация',
    key: 'menu-dialog-crypto',
    icon: 'fe fe-lock',
    url: '/dialog/crypto',
  },
  {
    title: 'Ротация партнерок',
    key: 'menu-dialog-affiliate',
    icon: 'fe fe-award',
    url: '/dialog/affiliate',
  },
]
