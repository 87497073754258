import { notification } from 'antd'
import userSlice, { getUserInfo } from 'redux/state/userSlice'
import { history } from 'redux/store'
import store from 'store'
import { api } from './api'

export const apiAuthSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    account: builder.query({
      query: () => 'auth/account',
      providesTags: ['AUTH'],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          const isAuthLocation = history.location.pathname.match(/auth/g)
          const isTvVersion = history.location.pathname === '/tv'

          if (isTvVersion) {
            return history.push('/tv')
          }

          if (data.error) {
            notification.error({ message: data.error })

            if (!isAuthLocation) {
              return history.push('/auth/login')
            }
          }

          const { _id, avatar, profile, emails } = data.result.user
          const projectId = await store.get('project._id')

          dispatch(
            getUserInfo({
              id: _id,
              name: profile?.name,
              email: emails[0]?.address,
              avatar,
              role: profile?.role,
              authorized: true,
              accessToken: data.result.accessToken,
            }),
          )

          if (projectId) {
            dispatch(api.endpoints.getCurrentProject.initiate(projectId))

            if (isAuthLocation) {
              return history.push('/')
            }

            return
          }

          return history.push('/auth/project')
        } catch (err) {
          getUserInfo(userSlice.initialState)
        }
      },
    }),
    login: builder.mutation({
      query: (body) => ({
        url: 'auth/token',
        method: 'POST',
        body,
      }),
      invalidatesTags: ({ result }) => (result?.accessToken ? ['AUTH'] : []),
    }),
    register: builder.mutation({
      query: (body) => ({
        url: 'auth/register',
        method: 'POST',
        body,
      }),
      invalidatesTags: ({ result }) => (result?.accessToken ? ['AUTH'] : []),
    }),
    newPassword: builder.mutation({
      query: (body) => ({
        url: 'auth/newPassword',
        method: 'POST',
        body,
      }),
    }),
    recover: builder.mutation({
      query: (body) => ({
        url: 'auth/recover',
        method: 'POST',
        body,
      }),
    }),
  }),
})
export const {
  useAccountQuery,
  useLoginMutation,
  useRegisterMutation,
  useNewPasswordMutation,
  useRecoverMutation,
} = apiAuthSlice
