import { TMenu } from '../menu.interface'

export const credentialsRole: TMenu[] = [
  {
    title: 'Реквизиты',
    key: 'menu-sales-pm',
    url: '/dialog/credentials',
    icon: 'fe fe-dollar-sign',
  },
]
