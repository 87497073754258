import { TMenu } from '../menu.interface'

export const mailAnalyticsRole: TMenu[] = [
  {
    title: 'Рассылки. Аналитика',
    key: 'menu-analytics-mail',
    url: '/mail/analytics',
    icon: 'fe fe-mail text-amber-300',
  },
]
