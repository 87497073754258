import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface IMenuBaseItem {
  title: string
  key: string
  url: string
  icon?: string
}

export interface IMenuItem extends IMenuBaseItem {
  category?: boolean
  children?: IMenuBaseItem[]
}

interface IMenuState {
  menuData: IMenuItem[]
}

const initialState: IMenuState = {
  menuData: [],
}

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    getMenuInfo: (state: IMenuState, action: PayloadAction<IMenuItem[]>) => {
      state.menuData = action.payload
    },
  },
})

export const { getMenuInfo } = menuSlice.actions

export default menuSlice.reducer
