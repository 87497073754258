import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface ITableState {
  type: 'table' | 'tile'
}

const initialState: ITableState = {
  type: 'table',
}

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    toggleType: (state: ITableState, action: PayloadAction<ITableState['type']>) => {
      state.type = action.payload
    },
  },
})

export const { toggleType } = tableSlice.actions

export default tableSlice.reducer
