import { dialogCategory } from './category/dialogCategory'
import { TMenu } from './menu.interface'
import { menuProjectOwner } from './menuProjectOwner'
import { accountsRole } from './role/accountsRole'
import { agentsRole } from './role/agentsRole'
import { botsRole } from './role/botsRole'
import { credentialsRole } from './role/credentialsRole'
import { dialogAnalyticsRole } from './role/dialogAnalyticsRole'
import { dialogTerminalRole } from './role/dialogTerminalRole'
import { incomeAssignmentRole } from './role/incomeAssignmentRole'
import { mailAnalyticsRole } from './role/mailAnalyticsRole'
import { mailRole } from './role/mailRole'
import { mediaRole } from './role/mediaRole'
import { rootRole } from './role/rootRole'
import { screenSoftRole } from './role/screenSoftRole'
import { workersRole } from './role/workersRole'

const getMenuData = (roles: string[] = [], isProjectOwner: boolean = false) => {
  if (!roles.length) return []

  if (isProjectOwner) return menuProjectOwner

  let menu: TMenu[] = []

  roles.forEach((role) => {
    switch (role) {
      case 'bots':
        menu = [...menu, ...botsRole]
        break

      case 'media':
        menu = [...menu, ...mediaRole]
        break

      // case 'mailings':
      //   menu = [...menu, ...mailingsRole]
      //   break

      case 'mail':
        menu = [...menu, ...mailRole]
        break

      case 'mailAnalytics':
        menu = [...menu, ...mailAnalyticsRole]
        break

      case 'accounts':
        menu = [...menu, ...accountsRole]
        break

      case 'screenSoft':
        if (!roles.find((el) => el === 'accounts')) {
          menu = [...menu, dialogCategory]
        }
        menu = [...menu, ...screenSoftRole]
        break

      case 'telegramStats':
        if (!roles.find((el) => el === 'accounts')) {
          menu = [...menu, dialogCategory]
        }
        menu = [...menu, ...dialogAnalyticsRole]
        break

      case 'dialogTerminal':
        if (!roles.find((el) => el === 'accounts')) {
          menu = [...menu, dialogCategory]
        }
        menu = [...menu, ...dialogTerminalRole]
        break

      case 'agents':
        menu = [...menu, ...agentsRole]
        break

      case 'credentials':
        menu = [...menu, ...credentialsRole]
        break

      case 'workers':
        menu = [...menu, ...workersRole]
        break

      // Always last item
      case 'root':
        menu = [...menu, ...rootRole]
        break

      case 'incomeAssignment':
        menu = [...menu, ...incomeAssignmentRole]
        break

      default:
        break
    }
  })

  return menu
}

export default getMenuData
