import qs from 'qs'
import { changeSettings } from 'redux/state/settingsSlice'
import { store } from 'redux/store'

const searchSettings = (search: string) => {
  const query = qs.parse(search, { ignoreQueryPrefix: true })
  Object.keys(query).forEach((key: string) => {
    let value
    switch (query[key]) {
      case 'false':
        value = false
        break
      case 'true':
        value = true
        break
      default:
        value = query[key]
        break
    }

    store.dispatch(
      changeSettings({
        setting: key,
        value,
      }),
    )
  })
}

export default searchSettings
