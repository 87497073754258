import { Tooltip } from 'antd'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useAppSelector } from 'hooks/useAppSelector'
import { FC, memo, useCallback } from 'react'
import { changeSettings } from 'redux/state/settingsSlice'

const ThemeWidget: FC = () => {
  const theme = useAppSelector((state) => state.settings.theme)
  const dispatch = useAppDispatch()

  const handleClick = useCallback(() => {
    dispatch(
      changeSettings({
        setting: 'theme',
        value: theme === 'default' ? 'dark' : 'default',
      }),
    )
  }, [dispatch, theme])

  return (
    <Tooltip title="Переключить светлую/тёмную тему" placement="left">
      <div
        onClick={handleClick}
        className="rounded-2xl shadow-lg text-xl p-3 bg-white hover:!bg-blue-500 hover:text-white dark:!bg-[#232135] text-blue-500 !fixed bottom-6 right-6 z-[999] leading-none cursor-pointer transition-all"
      >
        {theme === 'default' && <i className="fe fe-moon" />}
        {theme !== 'default' && <i className="fe fe-sun" />}
      </div>
    </Tooltip>
  )
}

export default memo(ThemeWidget)
