import { rootCategory } from '../category/rootCategory'
import { TMenu } from '../menu.interface'

export const rootRole: TMenu[] = [
  rootCategory,
  {
    title: 'Статистика',
    key: 'menu-manager',
    url: '/manager',
    icon: 'fe fe-hash',
    children: [
      {
        title: 'Версия для TV',
        key: 'menu-manager-tv',
        url: '/manager/tv',
      },
    ],
  },
]
