import { botPlatformCategory } from '../category/botPlatformCategory'
import { TMenu } from '../menu.interface'

export const botsRole: TMenu[] = [
  botPlatformCategory,
  {
    title: 'Боты',
    key: 'menu-bots',
    url: '/botPlatform/bots',
    icon: 'fe fe-cpu',
  },
  {
    title: 'Каналы',
    key: 'menu-channels',
    url: '/botPlatform/channels',
    icon: 'fe fe-message-square',
  },
  {
    title: 'Наполнение',
    key: 'menu-content',
    url: '/botPlatform/content',
    icon: 'fe fe-file-text',
  },
  {
    title: 'Аналитика (ЛИД)',
    key: 'menu-analytics',
    url: '/botPlatform/analytics',
    icon: 'fe fe-bar-chart',
  },
]
