import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import { createBrowserHistory } from 'history'
import menuReducer from './state/menuSlice'
import projectReducer from './state/projectSlice'
import settingsReducer from './state/settingsSlice'
import tableReducer from './state/tableSlice'
import userReducer from './state/userSlice'

import { api, dynamicApi, screenSoftApi } from './api/api'

export const history = createBrowserHistory()

const rootReducer = combineReducers({
  router: connectRouter(history),
  user: userReducer,
  project: projectReducer,
  menu: menuReducer,
  settings: settingsReducer,
  table: tableReducer,
  [dynamicApi.reducerPath]: dynamicApi.reducer,
  [screenSoftApi.reducerPath]: screenSoftApi.reducer,
  [api.reducerPath]: api.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      dynamicApi.middleware,
      screenSoftApi.middleware,
      api.middleware,
      routerMiddleware(history),
    ),
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
