import FeIcon from '../../components/FeIcon'
import { IAccessList } from './AccessList.interface'

const AccessList: IAccessList[] = [
  {
    name: 'Админка проекта',
    value: 'manageUsers',
    icon: <FeIcon className="user" />,
  },
  {
    name: 'Дашборд',
    value: 'dashboard',
    icon: <FeIcon className="activity" />,
  },
  {
    name: 'Боты',
    value: 'bots',
    icon: <FeIcon className="cpu" />,
  },
  {
    name: 'Рассылки Old',
    value: 'mailings',
    icon: <FeIcon className="mail" />,
  },
  {
    name: 'Аккаунты Telegram',
    value: 'accounts',
    icon: <FeIcon className="database" />,
  },
  {
    name: 'Аккаунты: Саппорт',
    value: 'accounts-support',
    icon: <FeIcon className="database" />,
  },
  {
    name: 'Гео, циклы, прочее',
    value: 'accounts-meta',
    icon: <FeIcon className="globe" />,
  },
  {
    name: 'Крипторотация – участники',
    value: 'incomeAssignment',
    icon: <FeIcon className="user-check" />,
  },
  {
    name: 'Скриншоты/чеки',
    value: 'screenSoft',
    icon: <FeIcon className="crop" />,
  },
  {
    name: 'Статистика личек',
    value: 'telegramStats',
    icon: <FeIcon className="box" />,
  },
  {
    name: 'Терминал диалогов',
    value: 'dialogTerminal',
    icon: <FeIcon className="inbox" />,
  },
  {
    name: 'Приемка: чтение',
    value: 'credentials',
    icon: <FeIcon className="dollar-sign" />,
  },
  {
    name: 'Приемка: запись',
    value: 'credentials-write',
    icon: <FeIcon className="dollar-sign" />,
  },
  {
    name: 'Приемка: ревизия',
    value: 'cardPoolRevision',
    icon: <FeIcon className="lock" />,
  },
  {
    name: 'Коды от Telegram',
    value: 'getCodeFromTelegram',
    icon: <FeIcon className="unlock" />,
  },
  {
    name: 'Dialogflow',
    value: 'agents',
    icon: <FeIcon className="message-circle" />,
  },
  {
    name: 'Сотрудники',
    value: 'workers',
    icon: <FeIcon className="user" />,
  },
  {
    name: 'Зарплата: менеджер',
    value: 'payroll.manager',
    icon: <FeIcon className="file-text" />,
  },
  {
    name: 'Зарплата: исполнитель',
    value: 'payroll.executor',
    icon: <FeIcon className="percent" />,
  },
  {
    name: 'Инвайты сотрудникам',
    value: 'giveInvite',
    icon: <FeIcon className="user-plus" />,
  },
  {
    name: 'Библиотека медиа',
    value: 'media',
    icon: <FeIcon className="folder" />,
  },
  {
    name: 'Логирование',
    value: 'logging',
    icon: <FeIcon className="code" />,
  },
  {
    name: 'Рассылки (new)',
    value: 'mail',
    icon: <FeIcon className="mail text-amber-300" />,
  },
  {
    name: 'Библиотека рассылок',
    value: 'mailTemplates',
    icon: <FeIcon className="folder" />,
  },
  {
    name: 'Аналитика рассылок',
    value: 'mailAnalytics',
    icon: <FeIcon className="mail" />,
  },

  // Always last item
  {
    name: 'Менеджмент',
    value: 'root',
    icon: <FeIcon className="hash" />,
  },
  {
    name: 'Service menu',
    value: 'service',
    icon: <FeIcon className="alert-circle" />,
  },
]

export default AccessList
