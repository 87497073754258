import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface IProjectState {
  _id: string
  title: string
  description: string
  owner: string
  members: string[]
  tokenProject: string | null
}

export const initialState: IProjectState = {
  _id: '',
  title: '',
  description: '',
  owner: '',
  members: [],
  tokenProject: null,
}

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    getProjectInfo: (state: IProjectState, action: PayloadAction<IProjectState>) => ({
      ...state,
      ...action.payload,
    }),
    tokenProject: (state: IProjectState, action: PayloadAction<IProjectState['tokenProject']>) => {
      state.tokenProject = action.payload
    },
    idProject: (state: IProjectState, action: PayloadAction<IProjectState['_id']>) => {
      state._id = action.payload
    },
  },
})

export const { getProjectInfo, tokenProject, idProject } = projectSlice.actions

export default projectSlice.reducer
