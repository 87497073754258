import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import store from 'store'

const prepareHeaders: FetchBaseQueryArgs['prepareHeaders'] = (headers) => {
  // TODO: Переписать на редакс
  const token = store.get('accessToken')
  // const token = getState().user.accessToken

  if (token) {
    headers.set('authorization', `Bearer ${token}`)
  }

  return headers
}

export default prepareHeaders
