import moment from 'moment'
import 'moment/locale/ru'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './styles/index.css'

import { ConfigProvider } from 'antd'
import ruRU from 'antd/lib/locale/ru_RU'
import { ConnectedRouter } from 'connected-react-router'
import { history, store } from 'redux/store'
import StylesLoader from './layouts/StylesLoader'

import Router from './routers'

moment.locale('ru')

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={ruRU}>
        <StylesLoader>
          <ConnectedRouter history={history}>
            <Router />
          </ConnectedRouter>
        </StylesLoader>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
