import { FC } from 'react'

const Footer: FC = () => {
  const version = process.env.REACT_APP_VERSION

  return (
    <div className="flex justify-center gap-2 p-3">
      Версия проекта: <span className="font-bold">{version}</span>
    </div>
  )
}

export default Footer
