import { workersCategory } from '../category/workersCategory'
import { TMenu } from '../menu.interface'

export const workersRole: TMenu[] = [
  workersCategory,
  {
    title: 'Сотрудники',
    key: 'menu-team-list',
    url: '/team/list',
    icon: 'fe fe-user',
  },
  {
    title: 'Должности',
    key: 'menu-team-positions',
    url: '/team/positions',
    icon: 'fe fe-briefcase',
  },
  {
    title: 'Зарплата',
    key: 'menu-team-payroll',
    url: '/team/payroll',
    icon: 'fe fe-file-text',
  },
]
