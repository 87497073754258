import { TMenu } from '../menu.interface'

export const dialogTerminalRole: TMenu[] = [
  {
    title: 'Просмотр',
    key: 'menu-messenger',
    url: '/dialog/messenger',
    icon: 'fe fe-inbox',
  },
]
