import { TMenu } from '../menu.interface'

export const mediaRole: TMenu[] = [
  {
    title: 'Библиотека',
    key: 'menu-media',
    url: '/botPlatform/media',
    icon: 'fe fe-folder',
  },
]
