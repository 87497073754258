import { TMenu } from '../menu.interface'

export const agentsRole: TMenu[] = [
  {
    title: 'Dialogflow',
    key: 'menu-accounts-dialogflow',
    url: '/dialog/dialogflow',
    icon: 'fe fe-message-circle',
  },
]
