import { useAppSelector } from 'hooks/useAppSelector'
import { reduce } from 'lodash'
import { FC, memo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { IMenuItem } from 'redux/state/menuSlice'
import { checkItemTitle } from './Breadcrumbs.config'
import { styles } from './Breadcrumbs.style'

const Breadcrumbs: FC = () => {
  const menuData = useAppSelector((state) => state.menu.menuData)
  const pathname = useAppSelector((state) => state.router.location.pathname)

  const getPath = useCallback(
    (data: IMenuItem[], url: string, parents: any[] = []): IMenuItem[] => {
      const items = reduce(
        data,
        (result: IMenuItem[], entry: IMenuItem) => {
          if (entry.url === url) {
            return [entry].concat(parents)
          }

          if (entry.children) {
            const nested = getPath(entry.children, url, [entry].concat(parents))
            const nestedFiltered = nested.filter((e) => !!e)

            return result.concat(nestedFiltered)
          }

          return result
        },
        [],
      )

      return items
    },
    [],
  )

  const toUpper = useCallback((str) => str.replace(/\b\w/g, (l: string) => l.toUpperCase()), [])

  const getBreadcrumbs = useCallback(() => {
    const [activeMenuItem] = getPath(menuData, pathname)

    const pathUrl = pathname.split('/')

    const isPathUrl = pathUrl[1] && pathUrl[1] === 'dialog' && pathUrl[2]

    if ((isPathUrl && pathUrl[2] === 'credentials') || (isPathUrl && pathUrl[2] === 'screenSoft')) {
      return []
    }

    if (activeMenuItem && pathUrl.length > 1) {
      return pathUrl.map((item, index) => {
        if (index === 0) {
          return null
        }

        if (index === pathUrl.length - 1) {
          return (
            <span key={item}>
              <span className={styles.arrow} />
              <strong className={styles.current}>{toUpper(activeMenuItem.title)}</strong>
            </span>
          )
        }

        const itemTitle = checkItemTitle(toUpper(item))

        return (
          <span key={item}>
            <span className={styles.arrow} />
            <span>{itemTitle}</span>
          </span>
        )
      })
    }
  }, [getPath, menuData, pathname, toUpper])

  const breadcrumbs = getBreadcrumbs()

  return breadcrumbs?.length ? (
    <div className={styles.breadcrumbs}>
      <div>
        <Link to="/">
          <i className="fe fe-home" />
        </Link>
        {breadcrumbs}
      </div>
    </div>
  ) : null
}

export default memo(Breadcrumbs)
